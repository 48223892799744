import React, { useEffect } from 'react';
import i18n from './utils/i18n';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DeleteInstructions = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get('lang') || i18n.language;

  useEffect(() => {
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  const boxStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const innerBoxStyles = {
    padding: '20px',
    width: '50%',
    border: '2px solid #ccc',
    borderRadius: '10px',
    backgroundColor: 'rgb(255 240 248)'
  };

  const imageStyles = {
    maxHeight: '600px',
    maxWidth: '600px',
    marginBottom: '10px'
  };

  const languageSelector = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px'
  };

  return (
    <div style={boxStyles}>
      <div style={languageSelector}>
        <select onChange={(e) => props.changeLanguage(e.target.value)} value={i18n.language}>
            <option value="en">English</option>
            <option value="de">Deutsch</option>
            <option value="ar">العربية</option>
        </select>
        <div className='lang-symbole'><i className='bx bx-world'></i></div>
      </div>
      <div style={{maxWidth: '80%', height: 'auto'}}><img src="./images/logo.png" alt="logo" /></div>
        <a href={'https://play.google.com/store/apps/details?id=com.menu_ace.menu_ace'}><img alt="play store" src="/images/play.png" /></a>
      <div style={innerBoxStyles}>
            <h1 style={{textDecoration: 'underline'}}>{t('delete_instructions.title')}</h1>
        {Array.from({ length: 6 }, (_, i) => (
          <div key={i} style={{ marginBottom: '20px' }}>
            <h2>{t('delete_instructions.step', {step: i + 1})}: {t(`delete_instructions.step${i + 1}`)}</h2>
            <div style={{display: 'flex', justifyContent:'center'}}><img style={imageStyles} src={`./images/delete-instructions/${lang}/${i + 1}.png`} alt={`${t('delete_instructions.step', {step: i + 1})}`} /></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeleteInstructions;
