import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../contexts/CartContext';

const MenuItem = ({ Id, Name, Description, Price, PreviousPrice, images, currency, itemallergies, itemadditives, translations }) => {
  const { updateCart, addToCart, getItemQuantity } = useContext(CartContext);
  const { t } = useTranslation();
  const [allergiesVisible, setAllergiesVisible] = useState(false);
  const [additivesVisible, setAdditivesVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentQuantity, setCurrentQuantity] = useState(getItemQuantity(Id));
  
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };
  const toggleDialog = () => {
    setDialogVisible(!dialogVisible);
  };

  const imageContainerRef = useRef(null);

  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    touchEndX.current = e.changedTouches[0].clientX;
    handleSwipe();
  };

  const handleSwipe = () => {
    if (touchEndX.current - touchStartX.current > 100) {
      // Swiped right
      previousImage();
    } else if (touchStartX.current - touchEndX.current > 100) {
      // Swiped left
      nextImage();
    }
  };
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (images.length) {
      setImgSrc(`https://res.cloudinary.com/dtmnzgc7x/image/upload/w_220,h_220,f_webp,q_80/uploads/${images[0].Path}`);
    } else {
      setImgSrc(`https://res.cloudinary.com/dtmnzgc7x/image/upload/w_220,h_220,f_webp,q_80/icons/default.png`);
    }
  }, [images]);

  const itemLabel = translations[0]?.Type === 2 && translations[0].Name.length ? translations[0].Name : Name;
  const itemDescription = translations[1]?.Type === 3 && translations[1].Name.length ? translations[1].Name : Description;
  const incrementQuantity = (e) => {
    e.stopPropagation();
    if (currentQuantity === 0) {
      addToCart({ Id, Price, quantity: 1 });
      setCurrentQuantity(1);
      return;
    }
    updateCart(Id, currentQuantity + 1);
    setCurrentQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decrementQuantity = (e) => {
    e.stopPropagation();
    if (currentQuantity === 0) {
      return;
    }
    updateCart(Id, currentQuantity - 1);
    setCurrentQuantity((prevQuantity) => prevQuantity - 1);
  };
  return (
    <>
      <div className='dish-box-wrapper'>
        <div className='dish-box' onClick={toggleDialog}>

          <div>
            <img className="food" src={imgSrc} alt={itemLabel} />
          </div>
          <div className='dish-box-info'>
            <div className='dish-box-name'>
              {itemLabel}
            </div>
            <div className='dish-box-price'>
              <div className='dish-prices-column'>
                <div className='dish-price'>{Price.toFixed(2)}{currency}</div>
                <div className='dish-previous-price'>{PreviousPrice ? `${PreviousPrice.toFixed(2)}${currency}` : ''}</div>
              </div>
            </div>
            <button className="item-add-to-cart" onClick={incrementQuantity}></button>
          </div>
        </div>

      </div>
      {dialogVisible && (
        <div className="dialog-overlay" onClick={toggleDialog}>
          <div className="dialog" onClick={(e) => e.stopPropagation()}>
            <div>
              <button className="close-dialog" onClick={toggleDialog}><i className='bx bxs-x-square bx-sm'></i></button>
            </div>
            <div>
              {
                images.length ? (
                  <>
                    <div
                      className="dialog-image"
                      ref={imageContainerRef}
                      onTouchStart={handleTouchStart}
                      onTouchEnd={handleTouchEnd}
                    >
                      <img
                        src={`https://res.cloudinary.com/dtmnzgc7x/image/upload/w_430,f_webp,q_80/uploads/${images[currentImageIndex].Path}`}
                        alt={itemLabel}
                      />
                      <button className={`slider-button slider-button-left ${currentImageIndex === 0 ? 'hidden' : ''}`} onClick={previousImage}><i className='bx bx-chevron-left'></i></button>
                      <button className={`slider-button slider-button-right ${currentImageIndex === images.length - 1 ? 'hidden' : ''}`} onClick={nextImage}><i className='bx bx-chevron-right'></i></button>
                    </div>
                  </>
                ) : ''
              }
            </div>
            <h3 className="dialog-title">{itemLabel}</h3>
            <div className='dialog-item-price'>
              <span>{Price.toFixed(2)}{currency}</span>
              <div>
              <button onClick={decrementQuantity}><i className='bx bxs-minus-circle'></i></button>
                <input
                  type="text"
                  className="quantity-input"
                  value={currentQuantity}
                  readOnly
                />
                <button onClick={incrementQuantity}><i className='bx bxs-plus-circle'></i></button>
              </div>
            </div>
            <p className="dialog-description">{itemDescription}</p>
            <div className="dialog-allergies">
              <div>
                <button className="allergies-toggle" onClick={() => setAllergiesVisible(!allergiesVisible)}>
                {t('general.allergies')} <span className={`caret ${allergiesVisible ? 'up' : 'down'}`} />
                </button>
              </div>
              <div>
                {allergiesVisible && itemallergies && itemallergies.map((allergy, index) => (
                  <div key={index} className="allergy-badge">
                    <img
                      className="allergy-icon"
                      src={`https://res.cloudinary.com/dtmnzgc7x/image/upload/w_50,h_50,f_webp,q_80/icons/allergies/${allergy.Allergy.Icon}`}
                      alt={t(`allergies_ns.${allergy.Allergy.Name}`)}
                    />
                    <span className="allergy-text">{t(`allergies_ns.${allergy.Allergy.Name}`)}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="dialog-additives">
              <div>
                <button className="additives-toggle" onClick={() => setAdditivesVisible(!additivesVisible)}>
                {t('general.additives')} <span className={`caret ${additivesVisible ? 'up' : 'down'}`} />
                </button>
              </div>
              <div>
                {additivesVisible && itemadditives && itemadditives.map((additive, index) => (
                  <div key={index} className="additive-badge">
                    <span className="additive-text">{t(`additives_ns.${additive.Additive.Name}`)}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuItem;
