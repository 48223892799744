import React from 'react';
import Category from './Category';

const CategoryCarousel = ({ categories, onCategorySelect, selectedCategory,  setSelectedCategory}) => {
  function scrollTo(index, event) {
    const div = event.target.closest(".category-box");
    div.scrollIntoView({
      block: "nearest",
      inline: "start",
      behavior: "smooth",
    });
    setSelectedCategory(parseInt(div.dataset.id));
    onCategorySelect(index);
  }
  return (
    <div className='categories-container'>
    {categories.map((category, index) => (
        <Category selected={selectedCategory === category.Id} clickHandler={(event) => scrollTo(index, event)} key={category.Id} {...category} />
      ))}
    </div>
  );
};

export default CategoryCarousel;
