import React, { useEffect, useState, useMemo } from 'react';

export default function TermsConditions() {
  const language = useMemo(() => localStorage.getItem('language_0'), []);
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlLang = params.get('lang');
    if (urlLang) {
      localStorage.setItem('language_0', urlLang);
    }
    const language =  urlLang || localStorage.getItem(`language_0`) || 'de';
    document.documentElement.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr');
  }, [language]);

  useEffect(() => {
    const loadComponent = async () => {
      let component;
      switch (language) {
        case 'en':
          component = await import('./TermsConditionsEn');
          break;
        case 'ar':
          component = await import('./TermsConditionsAr');
          break;
        default:
          component = await import('./TermsConditionsDe');
      }
      setComponent(() => component.default);
    };
    loadComponent();
  }, [language]);

  return Component ? <Component /> : null;
}
