import React, { createContext, useState, useEffect } from 'react';
import { extractUrlRestuarantId } from '../utils/general';

export const CartContext = createContext();
const EXPIRATION_TIME = 120;

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [translations, setTranslations] = useState(null);
  const id = extractUrlRestuarantId();
  const cartId = `cart_${id}`;
  const timestampId = `cart_updated_${id}`;

  const updateTimestamp = () => {
    localStorage.setItem(timestampId, Date.now().toString());
  };

  useEffect(() => {
    if (id === null) {
      return;
    }
    const localCart = localStorage.getItem(cartId);
    const lastUpdateTimestamp = localStorage.getItem(timestampId);
    if (lastUpdateTimestamp) {
      const currentTime = Date.now();
      const timeDifference = (currentTime - parseInt(lastUpdateTimestamp)) / (1000 * 60);
      if (timeDifference >= EXPIRATION_TIME) {
        setCart([]);
        localStorage.removeItem(cartId);
      } else if (localCart) {
        setCart(JSON.parse(localCart));
      }
    } else {
      updateTimestamp();
    }
  }, [id]);

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem(cartId);
    localStorage.removeItem(timestampId);
  };

  const updateCart = (itemID, newQuantity) => {
    let newCart;
    if (newQuantity === 0) {
      newCart = cart.filter(item => item.Id !== itemID);
    } else {
      newCart = cart.map(item => item.Id === itemID ? { ...item, quantity: newQuantity } : item);
    }
    setCart(newCart);
    localStorage.setItem(cartId, JSON.stringify(newCart));
    updateTimestamp();
  };

  const addToCart = (item) => {
    const existingItem = cart.find(cartItem => cartItem.Id === item.Id);
    if (existingItem) {
      // If the item already exists in the cart, update its quantity
      updateCart(item.Id, existingItem.quantity + 1);
    } else {
      // Otherwise, add the new item to the cart
      const newItem = { ...item, quantity: 1 };
      const newCart = [...cart, newItem];
      setCart(newCart);
      localStorage.setItem(cartId, JSON.stringify(newCart));
    }
    updateTimestamp();
  };

  const getItemQuantity = (itemId) => {
    return cart.find((item) => item.Id === itemId)?.quantity || 0
  };

  const getItemNameTranslation = (itemId) => {
    return translations.get(itemId);
  };

  return (
    <CartContext.Provider value={{ cart, updateCart, addToCart, clearCart, getItemQuantity, setTranslations, getItemNameTranslation }}>
      {children}
    </CartContext.Provider>
  );
};