import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from './utils/i18n';
import { useLocation } from 'react-router-dom';

const Confirm = (props) => {
    const apiUrl = '//menu-ace-api.vercel.app';
    const [isLoading, setIsLoading] = useState(true);
    const [confirmationSuccess, setConfirmationSuccess] = useState(false);
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get('lang');
    const code = queryParams.get('code');
    const [formError, setFormError] = useState('');
  
    useEffect(() => {
      if (lang) {console.log('lang', lang);
        i18n.changeLanguage(lang);
      }
    }, [lang]);
    const baseStyles = {
      maxWidth: '500px',
      width: '90%',
      padding: '20px',
      borderRadius: '5px',
      textAlign: 'center',
      margin: '0 auto'
  };
  
  const successStyles = {
      ...baseStyles,
      backgroundColor: '#DFF2BF',
      color: '#4F8A10',
      border: '1px solid #4F8A10'
  };
  
  const errorStyles = {
      ...baseStyles,
      backgroundColor: '#FFD2D2',
      color: '#D8000C',
      border: '1px solid #D8000C'
  };
  
    useEffect(() => {
        const fetchConfirmation = async () => {
            try {
                const response = await fetch(`${apiUrl}/confirmCode?code=${encodeURIComponent(code)}&lang=${encodeURIComponent(lang)}`);

                if (response.status === 200) {
                    setConfirmationSuccess(true);
                } else {
                    throw new Error(response.body);
                }
            } catch (error) {
                setFormError(t(`error_messages.${error}`));
            } finally {
                setIsLoading(false);
            }
        };

        fetchConfirmation();
    }, [code, lang]);

    if (isLoading) {
        return <div className="loadingSpinner">Loading...</div>; // Replace with your spinner component or CSS animation
    }
    return (
      <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          height: '100vh', 
          backgroundColor: '#572d86' 
      }}>
          
          <div>
              <Link to="/">
                <img style={{maxWidth: '80%',height: 'auto', display: 'block', margin: '0 auto'}} src="./images/logo.png" alt="logo" />
              </Link>
          </div>
          
          <div style={{marginTop: '20px', ...confirmationSuccess ? successStyles : errorStyles }}>
              {confirmationSuccess ? (
                  <>
                      {t('confirm.success_message')} 
                      <Link to="https://play.google.com/store/apps/details?id=com.menu_ace.menu_ace">{t('confirm.login_link')}</Link>
                  </>
              ) : (
                  <>
                   {t('confirm.error_message')}
                   <br/>
                    {formError}
                  </>
              )}
          </div>
      </div>
  );  
};

export default Confirm;
