import React, { useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../contexts/CartContext';

const CartModal = ({ onClose, currency, images, tel }) => {
  const { updateCart, cart, clearCart, getItemNameTranslation } = useContext(CartContext);
  const [total, setTotal] = useState(0);
  const modalRef = useRef(null);
  const notesRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const total = cart.reduce((total, item) => total + item.Price * item.quantity, 0);
    setTotal(total);
  }, [cart]);

  const handleQuantityChange = (e, itemID) => {
    const newQuantity = Number(e.target.value);
    if (isNaN(newQuantity) || newQuantity < 0) {
      return;
    }
    updateCart(itemID, newQuantity);
  };

  const handleDeleteItem = (itemID) => updateCart(itemID, 0);

  const handleOverlayClick = (e) => {
    if (e.target === modalRef.current) {
      onClose();
    }
  };

  const sendWhatsAppMessage = () => {
    let message = t('general.whatsapp_message_header') + "\n";
    message += cart.map(item => `${getItemNameTranslation(item.Id)}: ${item.quantity}`).join("\n");
    const notes = notesRef.current.value;
    if (notes.length > 0) {
      message += "\n-----------------------\n" + t('general.notes') + ":" + notes;
    }

    const encodedMessage = encodeURIComponent(message);
    window.open(`https://api.whatsapp.com/send?phone=${tel}&text=${encodedMessage}`, '_blank');
  };

  const cartNotEmpty = cart.length > 0;
  const fixedTotal = total.toFixed(2);
  return (
    <div className="modal-overlay" onClick={handleOverlayClick} ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header">
          <div>
            <div className='receipt-icon'><i className='bx bx-receipt' ></i></div>
            <h3>{t('general.order')}</h3>
          </div>
          <div>
            <button className="close-btn" onClick={onClose}><i className='bx bxs-x-square bx-sm'></i></button>
          </div>
        </div>
        <div className="cart-list" style={{ overflow: 'auto' }}>
          {cart.map((item, index) => {
            const itemName = getItemNameTranslation(item.Id);
            const itemTotal = (+(item.Price * item.quantity)).toFixed(2);
            const imgSrc = `https://res.cloudinary.com/dtmnzgc7x/image/upload/w_220,h_220,f_webp,q_80/${images[item.Id] ? 'uploads/' + images[item.Id] : 'icons/default.jpg'}`;

            return (
              <div key={index} className="cart-item-row">
                <div>
                  <div>
                    <img className="cart-item-image" alt={itemName} src={imgSrc}/>
                  </div>
                <div className="cart-item-info">
                    <div>{itemName}</div>
                  <span>
                      {item.Price.toFixed(2)} {currency}
                    </span>
                    <button className='delete-item' onClick={() => handleDeleteItem(item.Id)}>{t('general.remove')}</button>
                  </div>
                </div>
                <div className='cart-item-numbers'>
                <div className="cart-item-quantity">
                  <button onClick={() => handleQuantityChange({ target: { value: item.quantity + 1 } }, item.Id)}>+</button>
                    <input
                    className="quantity-input"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(e, item.Id)}
                  />
                  <button onClick={() => item.quantity > 1 ? handleQuantityChange({ target: { value: item.quantity - 1 } }, item.Id) : null}>-</button>
                </div>
                <div className='total-dishes'>
                  <span>{itemTotal} {currency}</span>
                </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="clear-btn-wrapper">
          {cartNotEmpty && <button className="delete-item" onClick={clearCart}>{t('general.delete_all')}</button>}
        </div>
        <div>
        {cartNotEmpty && <textarea className='message-notes' ref={notesRef} placeholder={t('general.notes') + "..."} />}
        </div>
        <div className='total-container'>
          <div>
          {cartNotEmpty && <button className='send'onClick={sendWhatsAppMessage}>
                <span className='whatsapp-symbol'><i className='bx bxl-whatsapp'></i></span>
                <span>{t('general.send')}</span>
            </button>}
          </div>
          <div className='total'>
            <p>{t('general.total')}:</p>
            <p>{fixedTotal} {currency}</p>
        </div>
        </div>
      </div>
    </div>
  );
};

export default CartModal;
