import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { extractUrlRestuarantId } from './general';

const id = extractUrlRestuarantId() || 0;
const params = new URLSearchParams(window.location.search);
const urlLang = params.get('lang');
if (urlLang) {
  localStorage.setItem('language_0', urlLang);
}
const language =  urlLang || localStorage.getItem(`language_${id}`) || 'de';
document.documentElement.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr');

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: language,
    fallbackLng: 'de',
    backend: {
      loadPath: '/translations/{{lng}}.json',
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;