import React from 'react';
import CategorySection from './CategorySection';

const MenuItems = ({
  categories,
  items,
  currency,
  language,
  itemRefs,
  selectedCategory,
}) => {

  return (
    <div className="items-container">
      {categories.map((category, index) => (
        <CategorySection
          key={category.Id}
          category={category}
          items={items}
          currency={currency}
          language={language}
          selectedCategory={selectedCategory}
          itemRef={(el) => {
            itemRefs.current[index] = el;
          }}
        />
      ))}
    </div>
  );
};


export default MenuItems;
