import React, { useState, useEffect } from 'react';
import i18n from './utils/i18n';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DeleteConfirmation = () => {
  const apiUrl = '//menu-ace-api.vercel.app';
  const [status, setStatus] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get('lang') || i18n.language;
  const code = queryParams.get('code');

  useEffect(() => {
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }
    if (code) {
      handleConfirmation();
    } else {
      setStatus('failure');
    }
  }, [lang, code]);

  const handleConfirmation = async () => {
    try {
      const response = await fetch(`${apiUrl}/deleteRestaurant?code=${encodeURIComponent(code)}&lang=${encodeURIComponent(lang)}`, {
        method: 'DELETE'
      });
      if (response.status === 200) {
        setStatus('success');
      } else {
        setStatus('failure');
      }
    } catch (error) {
      setStatus('failure');
    }
  };

  const boxStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column'
  };

  const innerBoxStyles = {
    padding: '20px',
    border: '2px solid #ccc',
    borderRadius: '10px',
    backgroundColor: status === 'success' ? '#d4edda' : '#f8d7da',
  };

  return (
    <div style={boxStyles}>
      <div><img src="./images/logo.png" alt="logo" /></div>
      <div style={innerBoxStyles}>
        {status === 'success' && <p>{t('delete_confirmation.success')}</p>}
        {status === 'failure' && <p>{t('delete_confirmation.failure')}</p>}
      </div>
    </div>
  );
};

export default DeleteConfirmation;
