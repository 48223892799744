import React, { useEffect, useState } from 'react';
import MenuItem from './MenuItem';
import NoItemsIcon from './NoItemsIcon';

const CategorySection = ({ category, currency, selectedCategory, items, itemRef, updateCart, addToCart, getItemQuantity }) => {
  const [isVisible, setIsVisible] = useState(selectedCategory === category.Id);

  useEffect(() => {
    setIsVisible(selectedCategory === category.Id);
  }, [selectedCategory, category.Id]);

  return (
  <div ref={itemRef} data-id={category.Id} className='dishes-container-parent'>
      <div key={category.Id} className='dishes-container'>
              {isVisible && (() => {
        const filteredItems = items.filter((item) => item.CategoryId === category.Id);
        if (filteredItems.length > 0) {
            return filteredItems.map((item) => (
                <MenuItem currency={currency} key={item.Id} {...item} updateCart={updateCart} addToCart={addToCart} getItemQuantity={getItemQuantity} />
            ));
        } else {
            return (
              <NoItemsIcon />
            );
        }
    })()}
    </div>
    </div>
  );
};

export default CategorySection;
