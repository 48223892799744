import React, { useRef } from 'react';

function LanguageModal({ onClose, onSelectLanguage, languages }) {
  const modalRef = useRef(null);

  const handleClose = (e) => {
    if (e.target === modalRef.current) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleClose} ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header"></div>
        <ul className="language-list">
          <li>
            <img src="/images/globe-symbol.webp" alt="languages" className="lang-symbol" />
          </li>
          {languages.map((lang) => (
            <li key={lang.Id} onClick={() => onSelectLanguage(lang.Id)}>
              {lang.Name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default LanguageModal;
