import React from 'react';

const Category = ({ Id, Name, Icon, clickHandler, selected, translations }) => {
const categoryLabel = translations.length > 0 && translations[0].Name.length > 0 ? translations[0].Name : Name;
  return (
    <div className={`category-box${selected ? ' selected-category-box' : ''}`} data-id={Id}  onClick={clickHandler}>
      <div><img src={Icon ? `https://res.cloudinary.com/dtmnzgc7x/image/upload/w_100,h_100,f_webp,q_80/icons/${Icon.Path}`:'https://res.cloudinary.com/dtmnzgc7x/image/upload/w_50,h_30,f_webp,q_80/icons/default.png'} alt={categoryLabel}/></div>
      <div className='category-box-title'><span>{categoryLabel}</span></div>
    </div>
  );
};

export default Category;
