import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import CategoryCarousel from './components/CategoryCarousel';
import MenuItems from './components/MenuItems';
import LanguageModal from './components/LanguageModal'; 
import CartModal from './components/CartModal';
import 'boxicons';
import { CartContext } from './contexts/CartContext';
import { extractUrlRestuarantId } from './utils/general';
import i18n from './utils/i18n';
import { useTranslation } from 'react-i18next';

const restuarantId = extractUrlRestuarantId();
const language = localStorage.getItem(`language_${restuarantId}`) || 'de';
i18n.loadLanguages([language]);

export default function Main() {
  import('./index.css');
  const { cart, setTranslations } = useContext(CartContext);
  const [categories, setCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [restaurant, setRestaurant] = useState([]);
  const [currency, setCurrency] = useState('€');
  const [appClasses, setAppClasses] = useState('App');
  const [language, setLanguage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [isCartOpen, setCartOpen] = useState(false);
  const [images, setImages] = useState([]);
  const storeUrl = 'https://play.google.com/store/apps/details?id=com.menu_ace.menu_ace';
  const { t } = useTranslation();
  const toggleCart = () => {
    setCartOpen(!isCartOpen);
  };
  
  const itemRefs = useRef([]);
  const apiUrl = '//menu-ace-api.vercel.app';

  const fetchData = async (languageId) => {
    const menu = await axios.get(`${apiUrl}/menu/${restuarantId}?languageId=${languageId||''}`);
    setSelectedCategory(menu.data.categories[0].Id);
    setCategories(menu.data.categories);
    setMenuItems(menu.data.items);
    const translations = new Map();
    menu.data.items.forEach((item) => {
      translations.set(item.Id, item.translations[0]?.Type === 2 && item.translations[0].Name.length ? item.translations[0].Name : item.Name);
    });
    setTranslations(translations);
    setRestaurant(menu.data.restaurant);
    const resImages = {};
    menu.data.items.forEach((item) => {
      resImages[item.Id] = item.images[0]?.Path;
    });
    setImages(resImages);
    if (!languageId){
      const defaultLangauge = menu.data.restaurant.DefaultLanguageId;
      setLanguage(defaultLangauge);
      const languageSymbol = languages.find((language) => language.Id === defaultLangauge)?.Symbol || 'de';
      i18n.changeLanguage(languageSymbol);
      localStorage.setItem(`language_${restuarantId}`, languageSymbol);
    }
    setCurrency(menu.data.restaurant.Currency.Symbol);
    setTimeout(() => {
    if (itemRefs.current.length) {
      itemRefs.current.forEach((itemRef) => {
        itemRef.classList.add('hidden', 'fadeIn');
        itemRef.addEventListener('animationend', (e) => {
          if (e.animationName === 'fadeIn') {
            itemRef.classList.remove('fadeIn');
          }
        });
      });
      itemRefs.current[0].classList.remove('hidden', 'fadeIn');
    }
    }, 20);
  };

  const handleSelectLanguage = (langId) => {
    setIsModalOpen(false);
    const languageSymbol = languages.find((language) => language.Id === langId)?.Symbol || 'de';
    i18n.changeLanguage(languageSymbol);
    localStorage.setItem(`language_${restuarantId}`, languageSymbol);
    setLanguage(langId);
    fetchData(langId);
  };

  useEffect(() => {
    const fetchLanguages = async () => {
      const res = await axios.get(`${apiUrl}/restaurant/languages/${restuarantId}`);
      const result = res.data.data;
      if (result) {
        setLanguages(result);
        return result;
      }
      return [];
    };
  
    const checkLanguageAndFetchData = async () => {
      const fetchedLanguages = await fetchLanguages();
      if (fetchedLanguages.length > 1){
        const language = fetchedLanguages.find((language) => language.Symbol === localStorage.getItem(`language_${restuarantId}`));
        if (language) {
          setLanguage(language.Id);
          fetchData(language.Id);
        } else {
          openLanguageModal();
        }
      } else {
        fetchData();
      }
    }
  
    checkLanguageAndFetchData();
  }, []);

  useEffect(() => {
    if (parseInt(language) === 1) {
      setAppClasses('App rtl');
    } else {
      setAppClasses('App');
    }
  }, [language]);
  

  const handleCategorySelect = (index) => {
    const selectedCategory = itemRefs.current[index];
    itemRefs.current.forEach((itemRef) => {
      if (!itemRef.classList.contains('hidden')) {
        itemRef.classList.remove('fadeIn');
      }
    });
    selectedCategory.classList.remove('hidden');
    selectedCategory.classList.add('fadeIn');
  };
  
  const openLanguageModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseLanguageModal = () => {
    setIsModalOpen(false);
     if (categories.length === 0) {
      fetchData();
    }
  };
  return (<div className={appClasses}>
    {isCartOpen && (
      <CartModal
        onClose={toggleCart}
        currency={currency}
        images={images}
        tel={restaurant.Tel}
      />
    )}
    {isModalOpen && (
      <LanguageModal
        isOpen={isModalOpen}
        onClose={handleCloseLanguageModal}
        onSelectLanguage={handleSelectLanguage}
        languages={languages}
      />
    )}
    <header className="menu-header">
      <div className="size">
        <div className="con">
          <div className="header-bar">
            {restaurant.Name?.split("/").map((part, index, array) => (
              <React.Fragment key={index}>
                {part}
                {index < array.length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
          <div className="circle">
            <div className="gradient-stroke">
              <div className="solid-stroke">
                <img
                  className="logo"
                  src={
                    restaurant.LogoPath
                      ? `https://res.cloudinary.com/dtmnzgc7x/image/upload/w_105,h_105,f_webp,q_80/uploads/logos/${restaurant.LogoPath}`
                      : `https://res.cloudinary.com/dtmnzgc7x/image/upload/w_105,h_105,f_webp,q_80/icons/default.png`
                  }
                  alt={restaurant.Name}
                />
              </div>
            </div>
          </div>
        </div>
        {languages.length > 1 && (
          <div>
            <button className="language-switch" onClick={openLanguageModal}>
              <box-icon name="world" animation="tada" color="#ffffff"></box-icon>
            </button>
          </div>
        )}
        <p>{restaurant.Slogan}</p>
      </div>
      <div>
        <div className="categories-area-container">
          <CategoryCarousel
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            categories={categories}
            onCategorySelect={handleCategorySelect}
          />
        </div>
      </div>
    </header>
    <main>
      <MenuItems
        currency={currency}
        language={language}
        categories={categories}
        items={menuItems}
        itemRefs={itemRefs}
        selectedCategory={selectedCategory}
      />
      <div className="cart-button" onClick={() => setCartOpen(true)}>
        <img
          src="/images/cart-table.webp"
          className="cart-table"
          alt="food table"
        />
        <span className="total-order-number">
          {cart.length > 0
            ? cart.reduce((total, item) => total + item.quantity, 0)
            : ""}
        </span>
      </div>
    </main>
    <footer>
      <p className="footer-container">
        {t("general.taamini")} &copy; {new Date().getFullYear()}
        <a href={storeUrl}>&nbsp;{t("general.try_taamini_app")}</a>
      </p>
    </footer>
  </div>
  );
};
