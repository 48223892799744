export function extractUrlRestuarantId() {
    const urlParts = window.location.href.split('/');
    const idStr = urlParts[urlParts.length - 1];
    if (idStr.length === 0){
        return null;
    }
    const result = idStr.match(/\d+/);
    if (!result || result.length === 0){
        return null;
    }
    return idStr.match(/\d+/)[0];
}