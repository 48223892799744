import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../utils/i18n';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const HomePage = (props) => {
  import('../home.css');
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [cookiesAccepted, setCookiesAccepted] = useState(localStorage.getItem('cookiesAccepted') === '1');
  const [isLoading, setIsLoading] = useState(false);
  const storeUrl = 'https://play.google.com/store/apps/details?id=com.menu_ace.menu_ace';
  const appleStoreUrl = 'https://apps.apple.com/de/app/taamini-app/id6467821095';
  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', '1');
    setCookiesAccepted(true);
  };
  const [isSticky, setIsSticky] = useState(false);
  const [isAnimate, setIsAnimate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  let prevScrollPos = 0;

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setIsSticky(window.scrollY > 0);
    setIsAnimate(currentScrollPos > prevScrollPos);
    prevScrollPos = currentScrollPos;
    setIsOpen(false);
  };

  const handleMenuClick = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const apiUrl = '//menu-ace-api.vercel.app';
  const sendEmail = async (event) => {
    event.preventDefault();
  
    setIsLoading(true);
    const formData = new FormData(event.target);
    const name = formData.get('name');
    const email = formData.get('email');
    const message = formData.get('message');
    const captchaToken = await handleReCaptchaVerify();
    if (!captchaToken) {
      alert(t('home.contact_us.form.please_complete_the_captcha'));
      return;
    }
  
    const payload = {
      name,
      email,
      message,
      captchaToken
    };
  
    try {
      const response = await fetch(`${apiUrl}/sendContactForm`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (response.status === 200) {
        alert(t('home.contact_us.form.success_message'));
        event.target.reset();
      } else {
        throw new Error('Email not sent');
      }
    } catch (error) {
      alert(t('home.contact_us.form.error_message'));
    } finally {
      setIsLoading(false);
    }

  };
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    return await executeRecaptcha('contact_form');
  }, [executeRecaptcha]);
  return (
    <div>
    <header className={`header ${isSticky ? 'sticky' : ''}`}>
        <a href="#home" className="logo"><img className="logo-img" alt="logo taamini" src="/images/logo.png" /></a>
        <div id="menu-icon" className={`bx bx-menu ${isOpen ? 'bx-x' : ''}`}
        onClick={handleMenuClick}></div>
        <ul className={`navbar ${isOpen ? 'open' : ''}`} >
            <li><a href="#home">{t('home.navbar.home')}</a></li>
            <li><a href="/register">{t('home.navbar.sign_up')}</a></li>
            <li><a href="#how-it-works">{t('home.navbar.how_it_works')}</a></li>
            <li><a href="#about">{t('home.navbar.about')}</a></li>
            <li><a href="#contact-us">{t('home.navbar.contact_us')}</a></li>
            <li>
                <div className='choose-lang'>
                    <select onChange={(e) => props.changeLanguage(e.target.value)} value={i18n.language}>
                        <option value="en">English</option>
                        <option value="de">Deutsch</option>
                        <option value="ar">العربية</option>
                    </select>
            <div className='lang-symbole'><i className='bx bx-world'></i></div>
            </div>
            </li>
        </ul>
    </header>
    <div className="bg-test">
        <section className="home" id="home">
            <div className="home-text">
                <h1>{t('home.home_text.welcome')}</h1>
                <p>{t('home.home_text.subtext')}</p>
                <div href="#" className="home-btn">{t('home.home_text.download_button')} <i className='bx bxs-chevrons-down bx-xs bx-fade-down'></i></div>
                <div className="store-logos">
                    <a href={storeUrl} target='_blank' rel='noreferrer'><img alt="google play store" src="/images/google-play.webp" /></a>
                    <a href={appleStoreUrl} target='_blank' rel='noreferrer'><img alt="apple app store" src="/images/app-store.webp" /></a>
                </div>
            </div>
        </section>

        <img className={`main-photo ${isAnimate ? 'animate' : ''}`}  alt="homepage taamini" src="/images/main.webp" />

    </div>
    <section id="how-it-works" className="how">
        <div className="container-s">
            <h2>{t('home.how_it_works.header')}</h2>
            <p>{t('home.how_it_works.description')}</p>
        </div>
        <img className="how-img" alt="how it works taamini" src="/images/how.webp" />
        <p className="steps"><strong>{t('home.how_it_works.steps_intro_start')}</strong>{t('home.how_it_works.steps_intro_end')}</p>

        <div className="container">
            <div className="row-items">
                <div className="container-box">
                    <div className="container-img">
                        <i className='bx bxs-download bx-lg' style={{ color: '#481b72' }}></i>
                        <h4>{t('home.how_it_works.step', {step: 1})}</h4>
                        <p>{t('home.how_it_works.step_1')}</p>
                    </div>
                </div>

                <div className="container-box">
                    <div className="container-img">
                        <i className='bx bxs-dish bx-lg' style={{ color: '#481b72' }}></i>
                        <h4>{t('home.how_it_works.step', {step: 2})}</h4>
                        <p>{t('home.how_it_works.step_2')}</p>
                    </div>
                </div>

                <div className="container-box">
                    <div className="container-img">
                        <i className='bx bx-qr-scan bx-lg' style={{ color: '#481b72' }}></i>
                        <h4>{t('home.how_it_works.step', {step: 3})}</h4>
                        <p>{t('home.how_it_works.step_3')}</p>
                    </div>
                </div>

            </div>
        </div>

    </section>
    <section id="about" className="about-con">
        <div className="about">

            <div className="about-pic">
                <img className="about-img" alt="smartphone taamini" src="/images/mobile.webp" />
            </div>
            <div>
                <h2>{t('home.about.header')}</h2>
                <p>{t('home.about.intro')}</p>
                <p>{t('home.about.simplicity')}</p>
                <p>{t('home.about.qr_code')}</p>
                <p>{t('home.about.closing')}</p>
                <p>{t('home.about.conclusion')}</p>
            </div>
        </div>
    </section>

    <section id="contact-us" className="contact">
        <img alt="contact us taamini" src="/images/contact.webp" />
        <div className="contactform">
            <form onSubmit={sendEmail}>
                <h2>{t('home.contact_us.title')}</h2>
                <div className="inputbox">
                    <input type="text" name="name" required="required"/>
                    <span>{t('home.contact_us.form.full_name')}</span>
                </div>

                <div className="inputbox">
                    <input type="text" name="email" required="required"/>
                    <span>{t('home.contact_us.form.email')}</span>
                </div>

                <div className="inputbox">
                    <textarea type="text" name="message" required="required"></textarea>
                    <span>{t('home.contact_us.form.message')}</span>
                </div>
                <div className="inputbox">
                    <input type="submit" name="" disabled={isLoading} value={t(isLoading ? 'home.contact_us.form.sending' : 'home.contact_us.form.submit_button')}/>
                </div>
            </form>
        </div>
    </section>
    <section id="contact">
        <div className="footer">
            <div className="main">
                <div className="list">
                    <h4>{t('home.footer.quick_links.title')}</h4>
                    <ul>
                        <li><a href="#about">{t('home.footer.quick_links.about')}</a></li>
                        <li><a href="/terms-and-conditions">{t('home.footer.quick_links.terms_and_conditions')}</a></li>
                        <li><a href="/privacy-policy">{t('home.footer.quick_links.privacy_policy')}</a></li>
                        <li><a href="/app-privacy-policy">{t('home.footer.quick_links.app_privacy_policy')}</a></li>
                        <li><a href="#contact-us">{t('home.footer.quick_links.help')}</a></li>
                    </ul>
                </div>

                <div className="list">
                    <h4>{t('home.footer.support.title')}</h4>
                    <ul>
                        <li><a href="#">{t('home.footer.support.about')}</a></li>
                        <li><a href="#contact-us">{t('home.footer.support.help')}</a></li>
                    </ul>
                </div>

                <div className="list">
                    <h4>{t('home.footer.contact_info.title')}</h4>
                    <ul>
                        <li><a>{t('home.footer.contact_info.location')}</a></li>
                        <li><a href={`mailto:${t('home.footer.contact_info.email')}`}>{t('home.footer.contact_info.email')}</a></li>
                        <li><a href={`tel:${t('home.footer.contact_info.phone')}`}>{t('home.footer.contact_info.phone')}</a></li>
                    </ul>
                </div>

                <div className="list">
                    <h4>{t('home.footer.connect.title')}</h4>
                    <div className="social">
                        <a href="https://www.facebook.com/taaminiapp"><i className='bx bxl-facebook'></i></a>
                        <a href="#"><i className='bx bxl-instagram-alt'></i></a>
                        <a href="#"><i className='bx bxl-twitter'></i></a>
                        <a href="#"><i className='bx bxl-linkedin'></i></a>
                    </div>
                </div>
            </div>
        </div>

        <div className="end-text">
            <p>{t('home.footer.end_text', {year: new Date().getFullYear()})}</p>
        </div>

    </section>
    {
      !cookiesAccepted && 
      <div id="cookies-bar">
          <p>{t('home.cookies_bar.text')} <a href="/Privacy&Policy.html">{t('home.cookies_bar.learn_more')}</a></p>
          <button onClick={acceptCookies} id="cookies-accept">{t('home.cookies_bar.accept_button')}</button>
      </div>
    }
   </div>
  );
};

export default HomePage;