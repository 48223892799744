import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from './utils/i18n';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './register.css';
import { useLocation } from 'react-router-dom';

const Register = (props) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get('lang') || i18n.language;

  useEffect(() => {
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);
  const apiUrl = '//menu-ace-api.vercel.app';
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    return await executeRecaptcha('registration_form');
  }, [executeRecaptcha]);

  const registerRestaurant = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData(event.target);
    const payload = Object.fromEntries(formData);
    const captchaToken = await handleReCaptchaVerify();

    if (!captchaToken) {
      alert(t('register.please_complete_the_captcha'));
      return;
    }

    const password = formData.get('password');
    const repeatPassword = formData.get('repeatPassword');
    if (password !== repeatPassword) {
        alert(t('register.password_mismatch'));
        setIsLoading(false);
        return;
    }

    if (password.length < 8) {
        alert(t('register.password_too_short'));
        setIsLoading(false);
        return;
    }

    payload.captchaToken = captchaToken;

    try {
      const response = await fetch(`${apiUrl}/registerRestaurant`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (response.status === 200) {
        setFormSuccess(true);
        event.target.reset();
      } else {
        throw new Error(response.body);
      }
    } catch (error) {
      setFormError(t(`error_messages.${error}`));
    } finally {
      setIsLoading(false);
    }
  };
  const languageSelector = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px'
  };

  return (
    <div>
      <div style={languageSelector}>
        <select onChange={(e) => props.changeLanguage(e.target.value)} value={i18n.language}>
            <option value="en">English</option>
            <option value="de">Deutsch</option>
            <option value="ar">العربية</option>
        </select>
        <div className='lang-symbole'><i className='bx bx-world'></i></div>
      </div>
      <section id="register" className={`register ${lang === 'ar' ? 'rtl' : ''}`}>
        <div><img style={{maxWidth: '80%',height: 'auto', display: 'block', margin: '0 auto'}} src="./images/logo.png" alt="logo" /></div>
        <div className="registerform">
            {formSuccess ? (
                <div style={{
                  backgroundColor: '#DFF2BF',
                  color: '#4F8A10',
                  border: '2px solid #4F8A10',
                  padding: '20px',
                  borderRadius: '5px',
                  textAlign: 'center'
                }}>
                  {t('register.success_message')}
                </div>
            ) : (
                <>
                  {formError && (
                    <div style={{
                      backgroundColor: '#FFD2D2',
                      color: '#D8000C',
                      border: '2px solid #D8000C',
                      borderRadius: '5px',
                      marginBottom: '20px',
                      textAlign: 'center'
                    }}>
                    {t('register.error_message')}
                    <br/>
                     {formError}
                    </div>
                  )}
                  <form onSubmit={registerRestaurant} style={{padding: '16px'}}>
                      <h2>{t('register.title')}</h2>
      
                      <div className="inputbox">
                          <input type="text" name="restaurantName" required autoComplete='off' placeholder=" " />
                          <span>{t('register.restaurant_name')}</span>
                      </div>
      
                      <div className="inputbox">
                          <input type="text" name="slogan" autoComplete='off' placeholder=" " />
                          <span>{t('register.slogan')}</span>
                      </div>
      
                      <div className="inputbox">
                          <input type="text" name="username" required placeholder=" " />
                          <span>{t('register.username')}</span>
                      </div>
      
                      <div className="inputbox">
                          <input type="email" name="email" required placeholder=" " />
                          <span>{t('register.email')}</span>
                      </div>
      
                      <div className="inputbox">
                          <input type="password" name="password" required placeholder=" " />
                          <span>{t('register.password')}</span>
                      </div>
                      <div className="inputbox">
                          <input type="password" name="repeatPassword" required placeholder=" " />
                          <span>{t('register.repeat_password')}</span>
                      </div>
      
                      <div className="inputbox">
                          <input type="tel" name="tel" required placeholder=" " />
                          <span>{t('register.tel')}</span>
                      </div>
      
                      <div className="inputbox">
                          <select name="language">
                              <option value="en">English</option>
                              <option value="de">Deutsch</option>
                              <option value="ar">العربية</option>
                          </select>
                          <span>{t('register.language')}</span>
                      </div>
      
                      <div className="inputbox">
                          <select name="currency">
                              <option value="1">€ - EUR</option>
                              <option value="2">$ - USD</option>
                          </select>
                          <span>{t('register.currency')}</span>
                      </div>
      
                      <div className="inputbox">
                          <input type="submit" name="" disabled={isLoading} value={t(isLoading ? 'register.sending' : 'register.submit_button')}/>
                      </div>
                  </form>
                </>
            )}
        </div>
      </section>
    </div>
  );
};

export default Register;
